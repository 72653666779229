<template>
  <div class="row">
    <div class="col-md-12">
      <KTCodePreview v-bind:title="'Data Equipment'">
        <template v-slot:preview>
          <b-row>
            <b-col md="12" class="mb-2">
              <b-button variant="outline-dark" @click="$router.push('/master/addequipment')">
                <span class="svg-icon svg-icon-sm svg-icon-dark">
                  <i class="flaticon2-plus"></i>
                </span>
                Create
              </b-button>
              <b-button
                class="ml-2"
                variant="outline-success"
                @click="$router.push('/master/importequipment')"
              >
                <span class="svg-icon svg-icon-sm svg-icon-success">
                  <i class="flaticon-upload"></i>
                </span>
                Import Excel
              </b-button>
            </b-col>
            <b-col md="12">
              <b-table
                head-variant="dark"
                bordered
                :items="items"
                :fields="fields"
                :current-page="currentPage"
                stacked="lg"
                show-empty
                responsive
                hover
                :no-local-sorting="true"
                @sort-changed="sortTable"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                @filtered="onFiltered"
                :busy="isBusy"
              >
                <template #thead-top="data">
                  <b-tr>
                    <b-th colspan="1"></b-th>
                    <b-th>
                      <b-form-input
                        id="filter-1"
                        size="sm"
                        @input="loadData"
                        v-model="search.nama_pabrik"
                        type="search"
                        placeholder="Cari Nama Pabrik..."
                      ></b-form-input>
                    </b-th>
                    <b-th>
                      <b-form-input
                        id="filter-2"
                        size="sm"
                        @input="loadData"
                        v-model="search.nama_plant"
                        type="search"
                        placeholder="Cari Nama Plant..."
                      ></b-form-input>
                    </b-th>
                    <b-th>
                      <b-form-input
                        id="filter-2"
                        size="sm"
                        @input="loadData"
                        v-model="search.nomor_equipment"
                        type="search"
                        placeholder="Cari Nomor Equipment..."
                      ></b-form-input>
                    </b-th>
                    <b-th>
                      <b-form-input
                        id="filter-4"
                        size="sm"
                        @input="loadData"
                        v-model="search.nama_equipment"
                        type="search"
                        placeholder="Cari Nama Equipment..."
                      ></b-form-input>
                    </b-th>
                    <b-th colspan="1"></b-th>
                  </b-tr>
                </template>

                <template #cell(no)="data">
                  {{ (currentPage - 1) * 10 + data.index + 1 }}
                </template>

                <template #cell(actions)="data">
                  <b-dropdown text=". . ." variant="danger" size="sm" no-caret>
                    <b-dropdown-item-button @click="info(data.item, $event.target)">
                      <div class="btn btn-icon btn-clean btn-sm btn-dropdown mr-1">
                        <span class="svg-icon svg-icon-sm svg-icon-success">
                          <i class="flaticon-eye text-success"></i>
                        </span>
                      </div>
                      View
                    </b-dropdown-item-button>
                    <b-dropdown-item-button
                      @click="$router.push('/master/editequipment/' + data.item.id_equipment)"
                    >
                      <div class="btn btn-icon btn-clean btn-sm btn-dropdown mr-1">
                        <span class="svg-icon svg-icon-sm svg-icon-primary">
                          <i class="flaticon2-pen text-primary"></i>
                        </span>
                      </div>
                      Edit
                    </b-dropdown-item-button>
                    <b-dropdown-item-button @click="toDelete(data)">
                      <div class="btn btn-icon btn-clean btn-sm btn-dropdown mr-1">
                        <span class="svg-icon svg-icon-sm svg-icon-danger">
                          <i class="flaticon2-trash text-danger"></i>
                        </span>
                      </div>
                      Delete
                    </b-dropdown-item-button>
                  </b-dropdown>
                </template>

                <template #table-busy>
                  <div class="text-center text-info my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
              </b-table>
              <b-modal id="info-modal" size="lg" title="Detail" ok-only @hide="resetInfoModal">
                <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-right"><b>Nama Pabrik :</b></b-col>
                  <b-col>{{ modalInfo.nama_pabrik }}</b-col>
                </b-row>

                <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-right"><b>Nama Plant :</b></b-col>
                  <b-col>{{ modalInfo.nama_plant }}</b-col>
                </b-row>

                <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-right"><b>Nomor Equipment :</b></b-col>
                  <b-col>{{ modalInfo.nomor_equipment }}</b-col>
                </b-row>

                <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-right"><b>Nama Equipment :</b></b-col>
                  <b-col>{{ modalInfo.nama_equipment }}</b-col>
                </b-row>

                <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-right"><b>Manufacturer :</b></b-col>
                  <b-col>{{ modalInfo.manufacturer }}</b-col>
                </b-row>

                <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-right"><b>Design Code :</b></b-col>
                  <b-col>{{ modalInfo.design_code }}</b-col>
                </b-row>

                <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-right"><b>Jenis Equipment :</b></b-col>
                  <b-col>{{ modalInfo.jenis_equipment }}</b-col>
                </b-row>

                <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-right"><b>Jenis Equipment :</b></b-col>
                  <b-col>{{ moment(modalInfo.tgl_mulai_operasi) }}</b-col>
                </b-row>

                <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-right"><b>Penggantian Ke :</b></b-col>
                  <b-col>{{ modalInfo.penggantian_ke }}</b-col>
                </b-row>

                <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-right"><b>Penggantian Terakhir :</b></b-col>
                  <b-col>{{ moment(modalInfo.penggantian_terakhir) }}</b-col>
                </b-row>
              </b-modal>
            </b-col>
            <b-col md="5">
              <b-pagination
                v-model="currentPage"
                @input="paginate"
                :total-rows="totalRows"
                :per-page="10"
                align="fill"
                size="sm"
                class="my-0"
              ></b-pagination>
            </b-col>
            <b-col md="5">
              <h4 class="float-right">
                Showing
                <span v-if="totalPerPage">1-{{ totalPerPage }}</span>
                <span v-else>0</span>

                of {{ totalRows }}
              </h4>
            </b-col>
          </b-row>
        </template>
      </KTCodePreview>
    </div>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'

import * as moment from 'moment'
import 'moment/locale/id'

export default {
  data() {
    return {
      sortBy: 'nama_equipment',
      sortDesc: false,
      sort_label: 'ASC',
      items: [],
      fields: [
        { key: 'no', label: 'No', sortable: true },
        { key: 'nama_pabrik', label: 'Nama Pabrik', sortable: true },
        { key: 'nama_plant', label: 'Nama Plant', sortable: true },
        { key: 'nomor_equipment', label: 'Nomor Equipment', sortable: true },
        { key: 'nama_equipment', label: 'Nama Equipment', sortable: true },
        { key: 'actions', label: 'Actions' },
      ],
      search: {
        nama_pabrik: '',
        nama_plant: '',
        nomor_equipment: '',
        nama_equipment: '',
      },
      modalInfo: {
        nama_pabrik: null,
        nama_plant: null,
        nomor_equipment: null,
        nama_equipment: null,
        manufacturer: null,
        design_code: null,
        jenis_equipment: null,
        tgl_mulai_operasi: null,
        penggantian_ke: null,
        penggantian_terakhir: null,
      },
      totalRows: 0,
      totalPerPage: 0,
      currentPage: 1,

      show: true,
      isBusy: false,
      dialog: false,
    }
  },
  components: {
    KTCodePreview,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Master', route: '' }, { title: 'Equipment' }])
  },
  created() {
    var self = this
    self.isBusy = true

    ApiService.get(
      '/master/equipment?page=' +
        this.currentPage +
        '&nama_pabrik=' +
        this.search.nama_pabrik +
        '&nama_plant=' +
        this.search.nama_plant +
        '&nomor_equipment=' +
        this.search.nomor_equipment +
        '&nama_equipment=' +
        this.search.nama_equipment +
        '&sortBy=' +
        this.sortBy +
        '&sortDesc=' +
        this.sort_label
    )
      .then(({ data }) => {
        if (data.status == 'ok') {
          self.items = data.data.data
          self.totalRows = data.data.total
          self.totalPerPage = data.data.data.length
          self.isBusy = false
        }
      })
      .catch(response => {
        console.log(response)
      })
  },
  methods: {
    info(item, button) {
      this.modalInfo.nama_pabrik = item.nama_pabrik
      this.modalInfo.nama_plant = item.nama_plant
      this.modalInfo.nomor_equipment = item.nomor_equipment
      this.modalInfo.nama_equipment = item.nama_equipment
      this.modalInfo.manufacturer = item.manufacturer
      this.modalInfo.design_code = item.design_code
      this.modalInfo.jenis_equipment = item.jenis_equipment
      this.modalInfo.tgl_mulai_operasi = item.tgl_mulai_operasi
      this.modalInfo.penggantian_ke = item.penggantian_ke
      this.modalInfo.penggantian_terakhir = item.penggantian_terakhir
      this.$root.$emit('bv::show::modal', 'info-modal', button)
    },
    resetInfoModal() {
      this.modalInfo.nama_pabrik = null
      this.modalInfo.nama_plant = null
      this.modalInfo.nomor_equipment = null
      this.modalInfo.nama_equipment = null
      this.modalInfo.manufacturer = null
      this.modalInfo.design_code = null
      this.modalInfo.jenis_equipment = null
      this.modalInfo.tgl_mulai_operasi = null
      this.modalInfo.penggantian_ke = null
      this.modalInfo.penggantian_terakhir = null
    },
    moment: function(date) {
      moment.locale('id')
      return moment(date, 'YYYY-MM-DD LTS').format('dddd, LL')
    },
    onFiltered() {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.loadData()
    },
    paginate() {
      this.loadData()
    },
    sortTable() {
      this.sort_label = 'ASC'
      if (this.sortDesc) {
        this.sort_label = 'DESC'
      }
      this.sortDesc = !this.sortDesc
      this.loadData()
    },
    async loadData() {
      var self = this
      self.isBusy = true
      // let current_page = this.filter == '' ? this.currentPage:1
      await this.timeout(100)
      ApiService.get(
        '/master/equipment?page=' +
          this.currentPage +
          '&nama_pabrik=' +
          this.search.nama_pabrik +
          '&nama_plant=' +
          this.search.nama_plant +
          '&nomor_equipment=' +
          this.search.nomor_equipment +
          '&nama_equipment=' +
          this.search.nama_equipment +
          '&sortBy=' +
          this.sortBy +
          '&sortDesc=' +
          this.sort_label
      )
        .then(({ data }) => {
          if (data.status == 'ok') {
            self.items = data.data.data
            self.totalPerPage = data.data.data.length
            self.isBusy = false
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    toDelete(row) {
      var id = row.item.id_equipment
      let self = this
      this.$confirm({
        auth: false,
        message: 'Anda yakin akan menghapus data tersebut?',
        button: {
          yes: 'Ya',
          no: 'Batal',
        },
        callback: confirm => {
          if (confirm) {
            ApiService.get('/master/equipment/delete/' + id).then(({ data }) => {
              if (data.status == 'ok') {
                self.isBusy = true
                var interval1 = null
                var i = 0
                self.loadData()
                interval1 = setInterval(function() {
                  if (i == 0) {
                    self.totalRows--
                    self.makeToast('warning', 'Data berhasil dihapus')
                    self.isBusy = false
                  } else {
                    clearInterval(interval1)
                  }
                  i++
                }, 1000)
              }
            })
          }
        },
      })
    },
    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    makeToast(variant = null, $content) {
      this.$bvToast.toast($content, {
        title: `${variant || 'default'}`,
        variant: variant,
        toaster: 'b-toaster-top-center',
        solid: true,
      })
    },
  },
}
</script>
